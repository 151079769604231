import { queryAll } from "./dom"

export function createScriptTag(script) {
    const newScript = document.createElement("script")

    newScript.innerHTML = script.innerHTML
    newScript.type = "text/javascript"
    newScript.setAttribute('loaded', 'true')

    if (script.src) {
        newScript.src = script.src
    }
    return newScript
}

export function runScriptsInsideElement(element) {
    const run = () => {
        if (!element.parentNode) {
            return
        }

        queryAll("script:not([data-component]):not([data-loaded])", element).forEach((script) => {
            if (script.type === 'text/plain') {
                if (!window.CookieConsent || !window.CookieConsent.consented) {
                    return
                }
                const requiredConsents = [script.dataset.cookieconsent] ?? ['stats','ads']
                if (requiredConsents.find(consName => window.CookieConsent.settings[consName] === false)) {
                    return
                }
            }

            const newScript = createScriptTag(script)
            script.parentNode.insertBefore(newScript, script)
            script.parentNode.removeChild(script)
        })
    }

    run()
}
